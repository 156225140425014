<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt="" @click="goBack" />
        <span class="center">代理管理</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">跟进的企业</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">跟进的企业详情</span>
      </div>
    </div>

    <!-- <Divider :title="'跟进的企业基本信息'"></Divider> -->
    <el-card class="box-card">
      <!-- <div class="left_top">
        <div class="left_icon">
          <span></span> <span>跟进的企业基本信息</span>
        </div>
      </div> -->
      <div class="edit_top">
        <div class="border_right">
          <el-card class="right_one">
            <div class="left_top">
              <div class="left_icon"><span></span> <span>基础信息</span></div>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/follow_1.png" alt="" /><span
                  >企业客户名称</span
                >
              </div>
              <span class="left-demo_right">{{ detailInfo.name }}</span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/follow_2.png" alt="" /><span
                  >统一信用编码</span
                >
              </div>
              <span class="left-demo_right">{{ detailInfo.idCardNumber }}</span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/follow_3.png" alt="" /><span
                  >行业：</span
                >
              </div>
              <span class="left-demo_right">{{ detailInfo.industry }}</span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/follow_4.png" alt="" /><span
                  >经营范围：</span
                >
              </div>

              <span
                class="left-demo_right"
                style=" width: calc(100% - 140px);"
                >{{ detailInfo.range }}</span
              >
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/follow_5.png" alt="" /><span
                  >客户类型：</span
                >
              </div>
              <span class="left-demo_right">{{ detailInfo.type }}</span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/follow_6.png" alt="" /><span
                  >客户地址：</span
                >
              </div>
              <span class="left-demo_right">{{ detailInfo.address }}</span>
            </div>
          </el-card>

          <el-card class="right_one">
            <div class="left_top">
              <div class="left_icon"><span></span> <span>跟进人信息</span></div>
            </div>
            <el-table style="width: 100%" :data="tableData">
              <el-table-column label="跟进人">
                <template slot-scope="scope">
                  <p>{{ scope.row.agentUserName }}</p>
                </template>
              </el-table-column>
              <el-table-column label="跟进企业">
                <template slot-scope="scope">
                  <p>{{ scope.row.agentPath.split('/')[3] }}</p>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </div>
        <el-card class="border_left">
          <div class="left_top">
            <div class="left_icon"><span></span> <span>维护信息</span></div>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>联系人：</span>
            </div>
            <span class="left-demo_right">{{ detailInfo.contacts }}</span>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>联系人电话：</span>
            </div>
            <span class="left-demo_right">{{ detailInfo.contactsPhone }}</span>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>邮箱：</span>
            </div>
            <span class="left-demo_right">{{ detailInfo.contactsMail }}</span>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>费率：</span>
            </div>

            <span class="left-demo_right" v-if="detailInfo.rate != '{}'">{{
              detailInfo.rate
            }}</span>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>状态（创建跟进中已完成已删除已失效）：</span>
            </div>
            <el-tag>
              <span v-if="detailInfo.updateState === '1'">
                创建
              </span>
              <span v-if="detailInfo.updateState === '2'">
                跟进中
              </span>
              <span v-if="detailInfo.updateState === '3'">
                已完成
              </span>
              <span v-if="detailInfo.updateState === '4'">
                已删除
              </span>
              <span v-if="detailInfo.updateState === '5'">
                已失效
              </span>
            </el-tag>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>状态更新时间：</span>
            </div>
            <span class="left-demo_right">{{ detailInfo.updateTime }}</span>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>添加时间：</span>
            </div>
            <span class="left-demo_right">{{ detailInfo.createTime }}</span>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>失效时间：</span>
            </div>
            <span class="left-demo_right">{{ detailInfo.failureTime }}</span>
          </div>
          <div class="left-demo">
            <div class="left-demo_left">
              <span>当前状态描述：</span>
            </div>
            <span class="left-demo_right">{{
              detailInfo.updateDescription
            }}</span>
          </div>
          <div class="left-demo1" style="  padding: 20px 10px;">
            <div class="left-demo_left" style="text-align:left;margin:10px 0">
              <span>备注：</span>
            </div>
            <el-input
              type="textarea"
              :rows="2"
              disabled
              placeholder=""
              v-model="detailInfo.remark"
            >
            </el-input>
          </div>
        </el-card>
      </div>
    </el-card>
    <el-dialog
      class="showImgDialog"
      width="680px"
      :center="true"
      :title="dialogTitle"
      :visible.sync="showImgVisible"
      :append-to-body="true"
    >
      <div class="imgBox">
        <img :src="dialogImgUrl" class="img-resource" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const request = api('/followEnterprise');
export default {
  data() {
    return {
      code: '',
      detailInfo: {
        code: '',

        name: '',

        idCardNumber: '',

        range: '',

        type: '',

        address: '',

        industry: '',

        contacts: '',

        contactsPhone: '',

        contactsMail: '',

        rate: '',

        remark: '',

        agentEnterprise: '',

        agentEnterpriseName: '',

        agentUser: '',

        agentUserName: '',

        agentGroup: '',

        agentGroupName: '',

        failureTime: '',

        updateState: '',

        updateDescription: '',

        updateTime: '',

        history: '',

        createTime: '',
      },
      tableData: [],
      dialogTitle: '',
      showImgVisible: false,
      dialogImgUrl: '',
    };
  },
  components: {},
  created() {
    this.code = this.$route.query.code;
    request('followEnterprise.describe.json')({ code: this.code }).then(
      (res) => {
        this.detailInfo = res;
        console.log(res);
        this.tableData.push(res);
        // this.tableData[0].agentPath = this.tableData[0].agentPath.split("/")[3];
        console.log(this.tableData[0].agentPath.split('/')[3]);
      },
    );
  },
  methods: {
    goBack() {
      window.history.go(-1);
    },
    showImg(img, title) {
      this.dialogImgUrl = img;
      console.log(this.dialogImgUrl);
      this.dialogTitle = title;
      this.showImgVisible = true;
    },
    dialogClose() {
      this.dialogImgUrl = '';
      this.dialogTitle = '';
      this.showImgVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}
.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.card-header {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}
.card-title {
  margin-right: 8px;
}
.card-btn {
  cursor: pointer;
}
.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.marginBottom {
  margin-bottom: 12px;
}
.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}
.aType {
  color: #659fe5;
  cursor: pointer;
}
.tooltipClas {
  width: 250px;
}
.basic-info {
  .warning {
    color: #e6a23c;
  }
  .success {
    color: #67c23a;
  }
  .danger {
    color: #f56c6c;
  }
  .info {
    color: #409eff;
  }
}
.defaultTips {
  display: inline-block;
  margin: 0 8px;
  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}
.edit_top {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  padding: 20px;
  width: 100%;
  .border_left {
    width: 48%;
    // flex-direction: column;
    // display: flex;
    .left_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      .left_icon {
        display: flex;
        align-items: center;
        span:nth-child(1) {
          padding: 8px 4px;
          background: #4977fc;
          margin-right: 10px;
        }
        span:nth-child(2) {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .left-demo {
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 20px 10px;
      border-bottom: 1px dashed #000;
      font-size: 15px;
      .left-demo_left {
        display: flex;
        align-items: center;
        img {
          width: 39px;
          height: 35px;
        }
        span {
          margin-left: 15px;
        }
      }
      .left-demo_right {
        color: #b4b4b4;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .left_buttom {
      margin: 140px 0 0 0;
      height: 100%;
      flex: 1;
    }
    // .left-demo:last-child {
    //   border: none;
    // }
  }
  .border_right {
    width: 48%;
    display: flex;
    flex-wrap: wrap;

    border-radius: 10px;

    .right_one {
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      .left_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        .left_icon {
          display: flex;
          align-items: center;
          span:nth-child(1) {
            padding: 8px 4px;
            background: #4977fc;
            margin-right: 10px;
          }
          span:nth-child(2) {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      .left-demo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px;
        color: #b4b4b4;
        border-bottom: 1px dashed #000;
        font-size: 15px;
        .left-demo_left {
          display: flex;
          align-items: center;
          color: #000;
          min-width: 180px !important;
          img {
            width: 39px;
            height: 35px;
          }
          span {
            margin-left: 15px;
          }
        }
      }
      .left-demo:last-child {
        border: none;
      }
    }
    .right_one:nth-child(2) {
      margin: 20px 0 0 0;
      .left-demo {
        border-bottom: 1px dashed #000;
      }
    }
  }
}
.left-demo_right {
  color: #b4b4b4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
